import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, CircularProgress } from '@mui/material';
// mocks_
import account from 'src/_mocks_/account';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Scrollbar from 'src/components/Scrollbar'
import NavSection from 'src/components/NavSection';
import AppContext from 'src/contexts/app/context';
import logoSvg from 'src/assets/images/logo-black-v2.png';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const RootStyleWithSidebar = styled('div')(({ theme }) => ({
  something: 'asd',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};


export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const {userData, dispatch, superFetch} = useContext(AppContext);
  const [sidebarConfig, setSidebarConfig] = useState([]);

  useEffect(() => {
    if (isOpenSidebar && !isDesktop)  {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    (async() => {
      try {
        const r = await superFetch.get('navigation_helper/get_sidebar_config');
        setSidebarConfig(r.data);
      } catch(e) {}
    })()
  }, [])

  const onLogout = () => {
    dispatch({type: 'REMOVE_TOKEN'});
    window.location = '/';
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <img src={logoSvg} />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            {/*<Avatar src={account.photoURL} alt="photoURL" />*/}
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userData.lastname} {userData.firstname}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userData.company_name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {sidebarConfig.length == 0? <CircularProgress /> : <NavSection navConfig={sidebarConfig} /> }

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
        >
          {/*<Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>
          */}

          <Button
            onClick={onLogout}
            target="_blank"
            variant="contained"
          >
            Kilépés
          </Button>

          <Button 
            onClick={() => document.documentElement.webkitRequestFullScreen()}
            target="_blank"
            variant="contained"
            >Fullscreen
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  const SidebarRoot = isOpenSidebar?RootStyleWithSidebar:RootStyle;

  return (
    <SidebarRoot>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open={isOpenSidebar}
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </SidebarRoot>
  );
}