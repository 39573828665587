
import Stack from '@mui/material/Stack';
import React, { useEffect, useReducer } from 'react';
import useListingQuery from 'src/hooks/useListingQuery';
import OrdersTab from './Tabs/OrdersTab';
import ProductsTab from './Tabs/ProductsTab';
import ShipmentTab from './Tabs/ShipmentTab';
import PackingContext from './PackingContext';
import { getInitialState } from './getInitialState';
import actions from './actions';
import useSuperFetch from 'src/hooks/useSuperFetch';

import BarcodeReader from 'react-barcode-reader';

import StyledElements from "./StyledElements";
import { Button } from '@mui/material';
import {isMobile, isTablet} from 'react-device-detect';

window.hasScanned = false;
export default function({picklistId}) {

    const [state, dispatch] = useReducer(actions, getInitialState())
    const {get, post} = useSuperFetch();

    const {data: orders, isLoading: ordersIsLoading, refresh: refreshOrders} = useListingQuery({url: 'packing_orders/list', data: {picklist_id: picklistId}});
    const {data: products, isLoading: productsIsLoading, refresh: refreshProducts} = useListingQuery({url: 'packing_products/list', data: {picklist_id: picklistId}});
    const {data: shipment, isLoading: shipmentIsLoading, refresh: refreshShipment} = useListingQuery({url: 'order_shipments/list', data: {order_id: state.currentOrderId}});

    async function actionAddProductToParcel(productId) {
        if(state.shipment) {
            const resp = await post('order_shipments/add_product_to_parcel', {
                shipment_id: state.shipment.id,
                picklist_id: picklistId,
                product_id: productId,
                quantity: 1
            });

            refreshProducts();
            refreshShipment({order_id: state.currentOrderId});
        }

    }

    async function actionRemoveProductFromParcel(productId, parcelId) {
        const resp = await post('order_shipments/remove_product_from_parcel', {
            shipment_parcel_id: parcelId,
            picklist_id: picklistId,
            product_id: productId,
            quantity: 1
        });

        refreshProducts();
        refreshShipment({order_id: state.currentOrderId});
    }

    async function actionCloseShipment() {
        const d = await get(`order_shipments/close_shipment?print_label=0&shipment_id=${state.shipment.id}&picklist_id=${picklistId}`);
        refreshOrders();
        refreshProducts();
    }

    async function actionCloseAndPrintShipment() {
        const d = await get(`order_shipments/close_shipment?print_label=1&shipment_id=${state.shipment.id}&picklist_id=${picklistId}`);
        refreshOrders();
        refreshProducts();
    }

    async function actionDeleteShipment() {
        const d = await get(`order_shipments/remove_order?order_id=${state.currentOrderId}&picklist_id=${picklistId}`);
        refreshOrders();
        refreshProducts();
    }

    async function actionAddParcel() {
        const resp = await post('order_shipments/add_parcel', {
            shipment_id: state.shipment.id,
        });
        refreshShipment({order_id: state.currentOrderId});
    }

    async function actionDeleteParcel() {
        const resp = await post('order_shipments/remove_parcel', {
            shipment_id: state.shipment.id,
        });
        refreshShipment({order_id: state.currentOrderId});
    }

    async function actionPrintLabel() {
        const resp = await post('order_shipments/print_label', {
            shipment_id: state.shipment.id,
            order_id: state.currentOrderId,
        });
    }

    function openOrder(orderId) {
        if(orderId == null) {
            let firstId = null;

            if(state.orders && state.orders.length > 0 && state.orders[0].order_id) {
                firstId = state.orders[0].order_id;
            }
    
            if(firstId != null) {
    
                if(state.currentOrderId != firstId) {
                    dispatch({type: 'SET_CURRENT_ORDER_ID', payload: firstId});
                }
            }
        } else {
            dispatch({type: 'SET_CURRENT_ORDER_ID', payload: orderId});
        }
    }

    useEffect(() => {
        dispatch({type: 'SET_ORDERS', payload: orders});
    }, [orders])

    useEffect(() => {
        dispatch({type: 'SET_PRODUCTS', payload: products});
    }, [products])

    useEffect(() => {
        openOrder();
    }, [state.orders])

    useEffect(() => {
        dispatch({type: 'SET_PICKLIST', payload: picklistId})
    }, [picklistId])

    useEffect(() => {
        dispatch({type: 'SET_SHIPMENT', payload: shipment})
    }, [shipment])

    useEffect(() => {
        if(isNaN(parseInt(state.currentOrderId)))
            return;
        refreshShipment({order_id: state.currentOrderId});

        (async() => {
            const orderData = await get(`orders/view/${state.currentOrderId}`);
            const productsData = await get(`order_picklist_products/list?order_id=${state.currentOrderId}&limit=200`);
            dispatch({type: 'SET_CURRENT_ORDER', payload: {
                ...orderData.data,
                products: productsData?.queryResult?.rows
            }});
        })()
    }, [state.currentOrderId])

    async function onBarcodeRead(barcode) {

        const isCmd = barcode.indexOf('CMD') == 0;

        if(isCmd) {
            switch(barcode) {
                case 'CMD-CLOSE':
                case 'CMD-1':
                    return actionCloseShipment();
                break;
                case 'CMD-PARCEL-A':
                case 'CMD-2':
                    return actionAddParcel();
                break;
                case 'CMD-PARCEL-R':
                case 'CMD-3':
                    return actionDeleteParcel();
                break;
                case 'CMD-PRINT-LABEL':
                case 'CMD-4':
                    return actionPrintLabel();
                break;
                case 'CMD-CLOSE-PRINT':
                case 'CMD-5':
                    return actionCloseAndPrintShipment();
                break;
            }
            return;
        }

        const found = state.products.filter(p => p?.product_barcodes?.split(',')?.indexOf(barcode) > -1);
        if(window.hasScanned) {
            return notify("Túl gyors vagy!", "error");
        }
        
        window.hasScanned = true;
        if(found.length == 0) {
            return notify("Ilyen cikkszám nincs a szedésben.", "error")
        } else {
            actionAddProductToParcel(state.products.filter(p => p?.product_barcodes?.split(',')?.indexOf(barcode) > -1)[0].product_id)

        }
        
        window.hasScanned = false;
    }

    if(isMobile || isTablet) {
        window.location = '/app/packing/quick?picklist_id='+picklistId;
        return;
    }

    if(ordersIsLoading == true)
        return null;

    return (
        <PackingContext.Provider value={{
            ...state,
            refreshOrders,
            refreshProducts,
            openOrder,
            actionAddProductToParcel,
            actionRemoveProductFromParcel,
            actionDeleteShipment,
            actionCloseShipment,
            actionCloseAndPrintShipment,
            actionPrintLabel,
            actionAddParcel,
            actionDeleteParcel,
            picklistId,
            dispatch
        }}>
            <BarcodeReader onScan={onBarcodeRead} minLength={5} />
            <Stack direction={`row`} sx={{flex: 1, maxHeight: '100%'}}>
                <StyledElements.OrdersWrapper><OrdersTab /></StyledElements.OrdersWrapper>
                <StyledElements.ProductsWrapper><ProductsTab /></StyledElements.ProductsWrapper>
                <StyledElements.PackingWrapper><ShipmentTab /></StyledElements.PackingWrapper>
            </Stack>
        </PackingContext.Provider>
    )
}