import React, { useState } from "react";
import useListingQuery from "src/hooks/useListingQuery";
import useSuperFetch from "src/hooks/useSuperFetch";
import Item from "./ProductList/Item";
import BarcodeReader from 'react-barcode-reader';
import List from "./ProductList/List";
import { Chip, CircularProgress, Dialog, DialogContent, Paper, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Resource from "src/vendor/Resource";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Form, SubmitButton, TextField } from "src/vendor/FormX";
import SelectFromTableField from "src/vendor/FormX/Inputs/SelectFromTableField";
import { Navigate } from "react-router-dom";
import ErrorBoundary from "src/components/ErrorBoundary";
import useAppState from "src/hooks/useAppState";
function useListingQueryWithMap(opts, mapFn) {

    const {data, isLoading, refresh} = useListingQuery({...opts});
    
    return {data: data?.map(mapFn), isLoading, refresh};
}


function getInstuctionText({product, selectedUID, locationId, quantity}) {
    if(locationId == null) {
        return `Keresd meg a ${product.location_name} helyet.`;
    }

    if(selectedUID == null) {
        return `Keresd meg a ${product.product_title} terméket.`; 
    }
    console.log(product.quantity, quantity);
    if(quantity == product.quantity) {
        return `Olvasd be a tárolódobozt, helyezd bele a termékeket és végeztünk.`;
    }

    return `Vegyél magadhoz ${product.quantity} db-ot a termékből`;
}

const options = ['Másik hely választása', 'Selejt kezelés', 'Hiányzó készlet'];

function CardHeaderOptions({onSwitchLocation, onCorrectLocationStock, onRemove, manualPick, skipPick}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      return true;
    };
  
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
            <MenuItem onClick={() => handleClose() && onSwitchLocation() }>Másik hely választása</MenuItem>
            <MenuItem onClick={() => handleClose() && onCorrectLocationStock()}>Hiány bejelentés</MenuItem>
            <MenuItem onClick={() => handleClose() && onRemove()}>Eltávolítás a listáról</MenuItem>
            <MenuItem onClick={() => handleClose() && manualPick()}>Manuális kiszedés</MenuItem>
            <MenuItem onClick={() => handleClose() && skipPick()}>Átugrás</MenuItem>
        </Menu>
      </div>
    );
}

function ProductCard({product, selectedUID, locationId, takenQuantity, setStockLocationSwitch, setStockLocationCorrection, removePick, setManualPick, setSkipPick}) {
    const {product_image_url, location_stock, location_id, product_title, location_name, quantity} = product;

    function doStockCorrection() {
        setStockLocationCorrection({
            currentQuantity: location_stock,
            locationId: location_id,
            productId: product.product_id
        })
    }

    function doLocationSwitch() {
        setStockLocationSwitch({
            product_id: product.product_id,
            old_location_id: product.location_id
        })
    }

    function doManualPick() {
        setManualPick({
            product_id: product.product_id,
            location_id: location_id,
            uid: product.uid,
            quantity: product.quantity
        })
    }

    function doSkipPick() {
        setSkipPick({
            product_id: product.product_id,
            location_id: location_id,
            uid: product.uid,
            quantity: product.quantity
        })
    }

    
    return (
        <Paper elevation={3} sx={{marginBottom: '20px'}}>
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    action={
                        <CardHeaderOptions
                            onSwitchLocation={doLocationSwitch}
                            onCorrectLocationStock={doStockCorrection}
                            onRemove={() => removePick(product.product_id, product.location_id)}
                            manualPick={doManualPick}
                            skipPick={doSkipPick}
                        />
                    }
                    title={product_title}
                    subheader={location_name}
                />
                <CardMedia
                    sx={{ margin: '0px auto', width: '200px', height: '200px' }}
                    image={product_image_url}
                    title={product_title}
                />
                <CardContent>
                    {/*<Typography gutterBottom variant="h5" component="div">
                        {product_title}
                    </Typography>*/}
                    <Stack direction="row" spacing={1}>
                        <Chip onClick={doStockCorrection} color={`warning`} icon={<ViewInArIcon />} label={`${location_stock} db`} />
                        {
                            product.location_id == locationId?
                            <Chip onClick={doLocationSwitch} color={`success`} icon={<CheckIcon />} label={location_name} />:
                            <Chip onClick={doLocationSwitch} color={`primary`} icon={<QuestionMarkIcon />} label={location_name} variant={`outlined`} />
                        }
                        {
                            quantity == takenQuantity?
                            <Chip color={`success`} icon={<CheckIcon />} label={`${quantity} / ${takenQuantity || 0} db`} />:
                            <Chip color={`primary`} icon={<YoutubeSearchedForIcon />} label={`${quantity} / ${takenQuantity || 0} db`} variant={`outlined`} />
                        }
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                        {getInstuctionText({product, selectedUID, locationId, quantity: takenQuantity})}
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default function({picklistId}) {
    const superFetch = useSuperFetch();

    const {messages, dispatch, beeps} = useAppState();
    const {data: productRows, isLoadding: productsAreLoading, refresh: refreshProducts} = useListingQueryWithMap({url: `picklist_products/list`, data: {
        picklist_id: picklistId
    }}, (item) => ({...item, uid: [item.location_id, item.product_id].join("/"), barcodes: item.product_barcodes?.split(',')?.filter(i => i.length > 1)}));

    /*const {data: locationRows, refresh: refreshLocations} = useListingQuery({url: `putawaylist_locations/list`, data: {
        picklist_id: picklistId
    }});*/

    const [locationId, setLocationId] = useState(null);
    const [scannedBarcode, setScannedBarcode] = useState(null);
    const [selectedUID, setSelectedUID] = useState(null);
    const [quantity, setQuantity] = useState(null);

    const [stockLocationSwitch, setStockLocationSwitch] = useState(null);
    const [stockLocationCorrection, setStockLocationCorrection] = useState(null);
    const [manualPick, setManualPick] = useState(null);

    if(productsAreLoading) {
        return <CircularProgress />
    }

    if(productRows.length == 0)
        return (<p>Töltés...</p>)

    
    const items = [
        ...(productRows.filter(i => parseInt(i.quantity) != 0).sort((a,b) => parseInt(a.order_index) > parseInt(b.order_index)?1:-1)),
        ...(productRows.filter(i => parseInt(i.quantity) == 0).sort((a,b) => parseInt(a.order_index) > parseInt(b.order_index)?1:-1))
    ];

    const listItems = items
        ?.slice(1)
        ?.map((item) => (
            <Item 
                key={item.product_id}
                selected={selectedUID===item.uid}
                pickedQuantity={quantity}
                onPick={(qty) => setQuantity(qty)}
                {...item} 
            />
        )
    )

    if(items.filter(i => i.quantity > 0).length == 0) {
        return (
            <Navigate to={`/app/picking/review?picklist_id=${picklistId}`} />
        )
    }

    async function onScan(scan) {

        if(scan.indexOf("LOC") === 0) {
            const scannedLocationId = scan.split('-')[1];
            if(items[0].location_id == scannedLocationId) {
                setLocationId(scannedLocationId);
                beeps.good();
                return;
            }

            if(selectedUID != null && quantity != null && locationId != null) {
                await superFetch.post('picklists/picked', {
                    picklist_id: picklistId,
                    product_id: items[0].product_id,
                    location_id: locationId,
                    quantity: quantity,
                    tote_location_id: scannedLocationId
                });
                
                
                setLocationId(null);
                setQuantity(null);
                setSelectedUID(null);

                await refreshProducts();

                return;
            }

            beeps.bad();
            return;
        }

        if(items[0].barcodes && items[0].barcodes.indexOf(scan) >= 0) {

            if(items[0].uid != selectedUID) {
                setSelectedUID(items[0].uid);
                setQuantity(1);
            } else {
                setQuantity((n) => n < items[0].quantity?(n+1):items[0].quantity);
            }
            beeps.good();
            

        } else {
            beeps.bad();
        }

        return;
    }

    async function setSkipPick(d) {
        await superFetch.post('picklists/skip_pick', {
            picklist_id: picklistId,
            product_id: d.product_id
        });
        await refreshProducts();
    }

    async function onSwitchPlaces(productId, newLocationId) {

    }

    if(items.length == 0)
        return null; 

    async function removePick(productId, locationId) {
        await superFetch.post('picklists/remove_pick', {
            picklist_id: picklistId,
            product_id: productId,
            location_id: locationId
        });
        await refreshProducts();
    }

    const StockLocationDialogForSwitching = (() => {
        if(stockLocationSwitch == null)
            return null;

        async function onSelected(locationId) {
            await superFetch.post('picklists/update_location', {
                picklist_id: picklistId,
                product_id: stockLocationSwitch.product_id,
                location_id: stockLocationSwitch.old_location_id,
                new_location_id: locationId
            });
            await refreshProducts();
            setStockLocationSwitch(null)
            //console.log(`new location for product ${stockLocationSwitch.product_id} is ${locationId}`);
        }

        function SelectButton({onClick}) {

            return <Button onClick={onClick} size="small" variant="outlined">kiválaszt</Button>;
        }

        return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setStockLocationSwitch(null)}>
                    <Resource data={{product_id: stockLocationSwitch.product_id}} name={`location_stocks`} specialRowActions={[
                        {icon: 'fa-eye', label: 'Kijelölés', onClick: (row) => onSelected(row.id), component: SelectButton}
                    ]} />
                </Dialog>
        )
    })();

    const StockLocationDialogForCorrection = (() => {
        if(stockLocationCorrection == null)
            return null;

        async function onCorrect(formData) {
            await superFetch.post('picklists/update_location_stock', {
                picklist_id: picklistId,
                product_id: stockLocationCorrection.productId,
                location_id: stockLocationCorrection.locationId,
                quantity: formData.quantity
            });
            await refreshProducts();
            setStockLocationCorrection(null)
        }

        return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setStockLocationCorrection(null)}>
                    <DialogContent>
                    <Form initialValues={{quantity: stockLocationCorrection.currentQuantity }} onSubmit={onCorrect}>
                        <TextField label={`Készlet DB`} name={`quantity`} />
                        <SubmitButton label={`Frissít`} />
                    </Form>
                    </DialogContent>
                </Dialog>
        )
    })()

    const ManulPickDialog = (() => {
        if(manualPick == null)
            return null;

        async function onCorrect(formData) {
            setLocationId(manualPick.location_id);
            setSelectedUID(manualPick.uid);
            setQuantity(formData.quantity);
            setManualPick(null)
        }

        async function onSelected(toteId) {
            setManualPick(null);
            onScan('LOC-'+toteId)
        }

        function SelectButton({onClick}) {

            return <Button onClick={onClick} size="small" variant="outlined">kiválaszt</Button>;
        }

        if(parseInt(quantity) > 0) {

            return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setManualPick(null)}>
                    <Resource data={{is_cart: 1}} name={`locations`} specialRowActions={[
                        {icon: 'fa-eye', label: 'Kijelölés', onClick: (row) => onSelected(row.id), component: SelectButton}
                    ]} />
                </Dialog>
            )
        }

        return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setManualPick(null)}>
                    <DialogContent>
                    <Form initialValues={{quantity: manualPick.quantity }} onSubmit={onCorrect}>
                        <TextField label={`Mennyiség`} name={`quantity`} />
                        <SubmitButton label={`Előkészít`} />
                    </Form>
                    </DialogContent>
                </Dialog>
        )
    })()
    
    return (<ErrorBoundary>
        <Stack sx={{width: '100%'}}>
            <BarcodeReader  minLength={5} onScan={onScan} />
            <ProductCard {...{setStockLocationSwitch, setManualPick, setSkipPick, setStockLocationCorrection, removePick, product: items[0], locationId, selectedUID, takenQuantity: quantity}} />
            <List>
                { listItems }
            </List>
        </Stack>

        {StockLocationDialogForSwitching}
        {StockLocationDialogForCorrection}
        {ManulPickDialog}
    </ErrorBoundary>)
}